import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyBIWh_nc2Zr39w9kX0_oMJw7UfXJMyywws",
    authDomain: "flexpert-co-il.firebaseapp.com",
    projectId: "flexpert-co-il",
    storageBucket: "flexpert-co-il.appspot.com",
    messagingSenderId: "668330040781",
    appId: "1:668330040781:web:975253962acb1337c43884",
    measurementId: "G-71NXSCY48D"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);

if (window.location.hostname === "localhost") {
    connectFirestoreEmulator(db, "localhost", 8080);
    connectFunctionsEmulator(functions, "localhost", 8599);
}

export {
    app,
    auth,
    db,
    functions
};


