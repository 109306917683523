import { DocumentData, Query, collection, doc, setDoc } from 'firebase/firestore';
import { useRef } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { db } from './firebase/config';

type AddNewProps = {
  path: string;
};

const AddNew: React.FC<AddNewProps> = ({ path }) => {
  const name = useRef<HTMLInputElement>(null);
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const docRef = doc(db, path, name.current?.value as string);
    await setDoc(docRef, { name: name.current?.value as string });

    (e.target as HTMLFormElement).reset();
  };

  return (
    <li>
      <form onSubmit={handleSubmit}>
        <input ref={name} />
        <button type="submit">Add</button>
      </form>
    </li>
  );
}


type OrgSitesListProps = {
  org: Organization;
};
const OrgSitesList: React.FC<OrgSitesListProps> = ({ org }) => {
  const query = collection(db, `organizations/${org.name}/sites`) as Query<Site, DocumentData>;
  const [docs, loading, error, snapshot] =
    useCollectionData<Site>(query);

  return (
    <div>
      {loading && <span>Loading...</span>}
      {error && <span>Error: {error.message}</span>}

      <ul>
        {docs?.map((site) => (
          <li key={Math.random()}>{site.name}</li>
        ))}
        <AddNew path={`organizations/${org.name}/sites`} />
      </ul>
    </div>
  );
};

type Site = {
  name: string;
};

type Organization = {
  name: string;
  sites: Site[];
};

const App = () => {

  const query = collection(db, 'organizations') as Query<Organization, DocumentData>;
  const [docs, loading, error, snapshot] =
    useCollectionData<Organization>(query);

  return (
    <div>
      <h1>Organizations</h1>
      {loading && <span>Loading...</span>}
      {error && <span>Error: {error.message}</span>}

      <ul>
        {docs?.map((org) => (
          <div key={Math.random()}>
            <li>
              <span>{org.name}</span>
              <OrgSitesList org={org} />
            </li>
          </div>
        ))}
        <AddNew path="organizations" />
      </ul>
    </div>
  );
}

export default App;
